<template>
  <div id="app" :class="{ 'nav-open': this.$sidebar.showSidebar }">
    <side-bar :title="userName" :sidebar-links="sidebarLinks">
      <mobile-menu v-slot:content></mobile-menu>
      <template v-if="$store.getters.isLoggedIn" slot="bottom-links">
        <internal-site-identity />
        <div class="text-center">
          <button class="btn btn-danger" @click="logout">
            Log Out
          </button>
        </div>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <dash-content @click="toggleSidebar"> </dash-content>
      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>

<style lang="less">
div.main-panel {
  position: relative;
  min-height: 100vh;
}
.main-panel > div.content {
  min-height: 100%;
  padding-bottom: 60px;
}
footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>
import DashContent from "./views/DashContent";
import ContentFooter from "./views/ContentFooter";
import MobileMenu from "./views/MobileMenu";
import TopNavbar from "./views/TopNavbar";
import SideBar from "./components/SidebarPlugin/SideBar";
import InternalSiteIdentity from "./components/InternalSiteIdentity";
import FeaturesMixin from "@/mixins/features.mixin";
export default {
  name: "SideBarApp",
  components: {
    MobileMenu,
    TopNavbar,
    DashContent,
    ContentFooter,
    SideBar,
    InternalSiteIdentity
  },
  mixins: [FeaturesMixin],
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$store.dispatch("startNewInquiry");
      if (this.$router.history.current.name !== "Login") {
        this.$router.push({ name: "Login" });
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    checkAuth() {
      const lastChecked = this.$store.getters.timeAuthLastChecked;
      let timeSinceLastChecked =
        lastChecked === null ? -1 : Date.now() - lastChecked;
      if (
        timeSinceLastChecked === -1 ||
        timeSinceLastChecked > process.env.VUE_APP_AUTH_FREQUENCY_MILLISECONDS
      ) {
        this.$store.dispatch("checkAuthStatus");
        if (
          (process.env.VUE_APP_LOCK_ROUTES_IF_NO_AUTH === "1" ||
            process.env.VUE_APP_USING_MAGIC_LINK_AUTH === "1") &&
          process.env.VUE_APP_AUTH_COMPLEX_KEY === "0" &&
          !this.$store.getters.isLoggedIn &&
          this.$router.currentRoute.name !== "Login"
        ) {
          this.$router.replace({ name: "Login" });
        }
      }
    }
  },
  computed: {
    userName() {
      if (
        this.$store.getters.isLoggedIn &&
        process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "0"
      ) {
        let userName = "";
        if (this.$store.getters.user?.forename) {
          userName += this.$store.getters.user.forename;
        }
        if (this.$store.getters.user?.surname) {
          userName += " " + this.$store.getters.user.surname;
        }
        if (userName.length > 0) {
          return userName;
        }

        if (this.$store.getters.user === undefined) {
          console.error("User logged in, but the user model is undefined");
          return "iCede"
        }

        return this.$store.getters.user?.email.split("@")[0];
      }

      return "iCede";
    },
    sidebarLinks() {
      if (this.inquiryOnlyMode()) {
        return [
          {
            name: "Inquiry",
            path: "/inquiry",
            icon: "fa fa-globe fa-2x"
          }
        ];
      } else {
        return [
          {
            name: "Inquiry",
            path: "/inquiry",
            icon: "fa fa-globe fa-2x"
          },
          {
            name: "Programmes",
            path: "/programmes",
            icon: "fa fa-list fa-2x"
          },
          {
            name: "User",
            path: "/login",
            icon: "fa fa-user fa-2x"
          },
        ];
      }
    }
  },
  mounted() {
    const t = this;
    const authPing = function() {
      t.checkAuth();
      setTimeout(authPing, process.env.VUE_APP_AUTH_FREQUENCY_MILLISECONDS);
    };
    authPing();

    // Vuex store schema changes or data reloads after a code/version update should be done here
    this.$store.dispatch('versionChanged').then(changed => {
      if (changed) {
        // TODO: TRIGGER A TOTAL REFRESH OF THE STORE AS THE SCHEMA COULD HAVE CHANGED IN MANY PLACES
        this.$store.commit('clearCountries');
        // This does not work before the user is logged in, as they are not authorised to request the countries from the API
        if (this.$store.getters.user !== undefined) {
          const defaultContinent = this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA) ? 'Africa' : 'Europe';
          this.$store.dispatch('loadCountriesForContinent', { continent: defaultContinent });
        }
      }
    });
  }
};
</script>
